import React from 'react'
//import {Link} from 'react-router-dom'

const Footer = () => {
  return (
    <div>
        <p>Copyright &copy; 2022</p>
        <a href="./about">About</a>
    </div>
  )
}

export default Footer